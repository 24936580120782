import { t } from '../../languages';
import { useEffect, useRef } from "react";

export function toRem(pixel: number) {
  return pixel / 16 + "rem";
}

export function isBoolean(val: any): val is boolean {
  return Object.prototype.toString.call(val) === "[object Boolean]";
}

export function isUndefined(val: any): val is undefined {
  return typeof val === "undefined";
}

export function isString(val: any): val is string {
  return Object.prototype.toString.call(val) === "[object String]";
}

export function isNull(val: any): val is null {
  return val === null;
}

export function isNumber(val: unknown): val is number {
  return Object.prototype.toString.call(val) === "[object Number]";
}

export function isRecordUnknown(val: unknown): val is Record<any, unknown> {
  return val instanceof Object;
}

export function randomString() {
  return Date.now().toString(16) + Math.random().toString(16).slice(2, 6);
}

// 参见：https://stackoverflow.com/questions/53966509/typescript-type-safe-omit-function
interface OmitFunc {
  <T extends object, K extends [...(keyof T)[]]>(obj: T, ...keys: K): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2];
  };
}

// 通过键名来筛选对象属性，返回一个新对象，原对象不会改变
export const omit: OmitFunc = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: (typeof obj)[K];
  };
  let key: keyof typeof obj;
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

/**
 * 使用上一次值的 hook
 *
 * 参考：https://zh-hans.reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param value 需要监听的值
 * @returns 该值上一次更新的值
 */
export function usePrevious<T = any>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/** 判断当前环境是否是开发环境 */
export function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

/** 判断当前环境是否是生产环境 */
export function isProduction() {
  return process.env.NODE_ENV === "production";
}

/** 判断当前环境是海外环境*/
export function isOverseas() {
  return process.env.REACT_APP_LANGUAGE === "en";
}

/** 判断当前环境是否是测试环境 */
export function isTest() {
  return process.env.REACT_APP_ENV === "test" && window.location.origin !== "https://score.leqiai.cn";
}

/** 限制字符长度，使用省略号进行补充 */
export function stringLimit(str: string, count: number, fill = "…") {
  if (!isString(str)) return "";

  if (str.length > count) {
    return str.substring(0, count) + fill;
  } else {
    return str;
  }
}

/** 文件转 base64 */
export async function fileToBase64(img: File) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setTimeout(() => {
        resolve(reader.result as string);
      }, 1000)
    );
    reader.readAsDataURL(img);
  });
}

export function beautifyJson(target: any) {
  try {
    if (isString(target)) return JSON.stringify(JSON.parse(target), null, 4);
    else return JSON.stringify(target, null, 4);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function SafeJSONParse(value: any, def: any = undefined): any {
  try {
    return JSON.parse(value!) ?? def;
  } catch (error) {
    console.error(error);
    return def;
  }
}

export function arrayDelForSlice<T>(arr: T[], start: number, end?: number): T[] {
  const newArr = Array.from(arr);
  newArr.slice(start, end);

  return newArr;
}

export function arraySplice<T>(sourceArray: T[], ...args: Parameters<Array<T>["splice"]>) {
  const newArray = Array.from(sourceArray);
  newArray.splice(...args);
  return newArray;
}

export function arraySet<T>(sourceArray: T[], index: number, value: T) {
  const newArray = Array.from(sourceArray);
  newArray[index] = value;
  return newArray;
}

/**
 * 中文拼音分类
 *
 * https://www.cnblogs.com/cangqinglang/p/8992575.html
 *
 * @param zhArray
 * @returns
 */
export function zhType(source: string) {
  if (!String.prototype.localeCompare) {
    console.warn("浏览器不兼容");
    return source;
  }

  const letters = "abcdefghjklmnopqrstwxyz";
  const zhs = "阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥扨它穵夕丫帀".split("");

  const index = zhs
    .concat(source)
    .sort((p, n) => p.localeCompare(n, "zh"))
    .findIndex((t) => t === source);

  return letters.charAt(index - 1);
}

type ZHGroupItem<T> = {
  letter: string;
  members: T[];
};

/** 中文安装拼音排序 */
export function zhGroup<T>(arr: T[], getZh: (item: T) => string) {
  const sorted = Array.from(arr).sort((a, b) => getZh(a).localeCompare(getZh(b)));

  return "abcdefghjklmnopqrstwxyz".split("").map((letter) => {
    const item: ZHGroupItem<T> = {
      letter,
      members: [],
    };

    while (sorted.length > 0 && zhType(getZh(sorted[0])).localeCompare(letter) === 0) {
      item.members.push(sorted.shift()!);
    }

    return item;
  });
}

/**
 * 记忆函数
 *
 * @param targetFunction 需要进行计算缓存的函数
 * @param createMemoryID 获取记忆键的函数
 * @returns 一个 targetFunction 的高阶记忆函数，附加 historyExec 对记忆进行控制
 */
export function memoryFunction<P extends Array<unknown>, R>(
  targetFunction: (...p: P) => R,
  createMemoryID: (args: P) => any = JSON.stringify
) {
  const memoryMap = new Map();

  function HOFTargetFunction(...p: P): R {
    const MemoryID = createMemoryID(p);

    if (memoryMap.has(MemoryID)) {
      return memoryMap.get(MemoryID);
    }

    const result = targetFunction(...p);
    memoryMap.set(MemoryID, result);

    return result;
  }

  return Object.assign(HOFTargetFunction, { memoryMap });
}

interface PixelConversionParam {
  /** 设计稿尺寸 */
  baseSize?: number;
  /** 最大伸缩尺寸 */
  maxSize?: number;
  /** 转化的单位 */
  unit?: "vw" | "vmin";
}

/** 像素转换 */
export function pixelConversion({ baseSize = 375, maxSize = 750, unit = "vmin" }: PixelConversionParam = {}) {
  return (pixel: number) => ((pixel / Math.min(baseSize, maxSize)) * 100).toString() + unit;
}

export function toAppDownload(type:string){
  if(type === 'android'){
    window.open('https://play.google.com/store/apps/details?id=com.infinite.score', '_blank');
  }else if(type === 'ios'){
    window.open('https://apps.apple.com/us/app/JoyIn-score/id1644834212', '_blank');
  }
}