import { t } from '../languages';
import LinearProgress from "@mui/material/LinearProgress";
import { useDebounce } from "ahooks";
import { useEffect, useState } from "react";

export default function LoadingPage() {
  const [delay, setDelay] = useState(false);

  const waitDelay = useDebounce(delay, { wait: 1500 });

  useEffect(() => {
    setDelay(true);

    return () => setDelay(false);
  }, []);

  return <div style={{ color: "#EFD386" }}>{waitDelay && <LinearProgress color="inherit" />}</div>;
}
