import { t } from '../../languages';
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { TextToastFeedbackGlobal } from "components/TextToastFeedback";
// import VConsoleDebug from "lib/VConsoleDebug";

// VConsoleDebug();

const StyledH5 = styled.div`
  max-width: 750px;
  margin: auto;
`;

export default function H5() {
  return (
    <>
      <TextToastFeedbackGlobal />
      <StyledH5>
        <Outlet />
      </StyledH5>
    </>
  );
}
