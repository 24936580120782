import { t } from './languages';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// 后端的错误捕获
// Sentry.init({
//   dsn:
//     window.location.protocol === "http"
//       ? "http://7c30c2e375174c39aa2114f3ff5f5f9b@106.14.200.77:9000/9" // 测试地址
//       : "http://919a78d506bc461faafd393f90cbdd09@106.14.200.77:9000/8", // 正式地址
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
//   release: " 1.2.0",
// });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
