import { t } from '../languages';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * 重定向组件
 *
 * 本页面仅接受一个查询参数 u （url）获取一个重定向 url，并且使用强匹配 location.search.slice(3) 来匹配 ?u= 后的 url 数据
 */
export default function Redirect() {
  const url = window.location.search.slice(3);

  const redirect = useCallback(() => {
    window.location.replace(url);
  }, [url]);

  useEffect(redirect, [redirect]);

  return (
    <>
      <div style={{ color: "#EFD386" }}>
        <LinearProgress color="inherit" />
      </div>
      <Box sx={{ textAlign: "center", pt: 20 }}>
        <Typography variant="subtitle1">如果您的页面没有跳转，可以点击手动跳转</Typography>
        <Button onClick={redirect}>手动跳转</Button>
      </Box>
    </>
  );
}
