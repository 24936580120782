import { t } from '../languages';
import { Outlet, redirect, RouteObject } from "react-router-dom";
import H5 from "page/H5";
import { lazy } from "react";

//email 激活
const Activate = lazy(() => import(/* webpackChunkName: "activate" */ "page/H5/Activate"));

// 分享页面
const Share = lazy(() => import(/* webpackChunkName: "share" */ "page/H5/Share/Share"));
const ShareIndex = lazy(() => import(/* webpackChunkName: "share" */ "page/H5/Share/ShareIndex"));


// 邀请码相关页面
const YQM = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM"));
const YQMIndex = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMIndex"));
const YQMEnd = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMEnd"));
const YQMGet = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMGet"));
const YQMSuccess = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMSuccess"));
const YQMList = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMList"));
// ios 活动相关
const YQMIOSLogin = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMIOSLogin"));
const YQMGetIOS = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMGetIOS"));
const YQMSuccessIOS = lazy(() => import(/* webpackChunkName: "yqm" */ "page/H5/YQM/YQMSuccessIOS"));

// 邀请码相关页面 version 2
const YQMV2List = lazy(() => import(/* webpackChunkName: "yqmv2" */ "page/H5/YQMV2/YQMV2List"));
const YQMV2Index = lazy(() => import(/* webpackChunkName: "yqmv2" */ "page/H5/YQMV2/YQMV2Index"));
const YQMV2Get = lazy(() => import(/* webpackChunkName: "yqmv2" */ "page/H5/YQMV2/YQMV2Get"));
const YQMV2Success = lazy(() => import(/* webpackChunkName: "yqmv2" */ "page/H5/YQMV2/YQMV2Success"));
const YQMV2IndexTest = lazy(() => import(/* webpackChunkName: "yqmv2" */ "page/H5/YQMV2/YQMV2IndexTest"));


// 邀请码相关页面 version 3
const YQMV3List = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV3/YQMV3List"));
const YQMV3Index = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV3/YQMV3Index"));
const YQMV3Get = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV3/YQMV3Get"));
const YQMV3Success = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV3/YQMV3Success"));
const YQMV3IndexTest = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV3/YQMV3IndexTest"));


// 邀请码相关页面 version 4
const YQMV4List = lazy(() => import(/* webpackChunkName: "yqmv4" */ "page/H5/YQMV4/YQMV4List"));
const YQMV4Index = lazy(() => import(/* webpackChunkName: "yqmv4" */ "page/H5/YQMV4/YQMV4Channel"));
const YQMV4Get = lazy(() => import(/* webpackChunkName: "yqmv4" */ "page/H5/YQMV4/YQMV4Get"));


const YQMV5List = lazy(() => import(/* webpackChunkName: "yqmv5" */ "page/H5/YQMV5/YQMV5List"));
const YQMV5Code = lazy(() => import(/* webpackChunkName: "yqmv5" */ "page/H5/YQMV5/YQMV5Code"));
const YQMV5Index = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV5/YQMV5Index"));
const YQMV5Get = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV5/YQMV5Get"));
const YQMV5Success = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV5/YQMV5Success"));
const YQMV5Inlet = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV5/YQMInlet"));

const YQMV4Inlet = lazy(() => import(/* webpackChunkName: "yqmv3" */ "page/H5/YQMV4/YQMInlet"));
// 活动 领取邀请码
const AcGetVip = lazy(() => import(/* webpackChunkName: "acGetVip" */ "page/H5/AcGetVip"));
const AcGetVipExtension = lazy(() => import(/* webpackChunkName: "acGetVip" */ "page/H5/AcGetVip/Extension"));

// 购买 vip
const BuyVip = lazy(() => import(/* webpackChunkName: "buyVip" */ "page/H5/BuyVip"));
const BuyVipEnd = lazy(() => import(/* webpackChunkName: "buyVip" */ "page/H5/BuyVip/BuyVipEnd"));
const BuyVipSell = lazy(() => import(/* webpackChunkName: "buyVip" */ "page/H5/BuyVip/BuyVipSell"));
const BuyVipSell2 = lazy(() => import(/* webpackChunkName: "buyVip" */ "page/H5/BuyVip/BuyVipSell2"));

// 新音乐周报
const MusicWeeklyMini = lazy(() => import(/* webpackChunkName: "MusicWeekly" */ "page/H5/MusicWeekly/MusicWeeklyMini"));
const MusicWeeklyGet = lazy(() => import(/* webpackChunkName: "MusicWeekly" */ "page/H5/MusicWeekly/MusicWeeklyGet"));

// 新橄榄树音乐
const AcOliveBookmarkIndex = lazy(
  () => import(/* webpackChunkName: "ac-olive-bookmark" */ "page/H5/ac-olive-bookmark/AcOliveBookmarkIndex")
);

// 新 LCME 推广分销
const Extension = lazy(() => import(/* webpackChunkName: "acGetVip" */ "page/H5/Extension"));

// 蓝牙翻页踏板
const Pedal = lazy(() => import(/* webpackChunkName: "padel" */ "page/H5/Pedal"));
const Pedal2 = lazy(() => import(/* webpackChunkName: "padel" */ "page/H5/Pedal/Pedal2"));

/** 服务于 app 的 h5 页面 */
const H5Routes: RouteObject = {
  path: "/h5",
  element: <H5 />,
  children: [
     // email 激活
     {
      path: "activate",
      element: <Activate />,
      children: [
        {
          index: true,
          element: <Activate />,
        },
      ],
    },
    // 分享
    {
      path: "share",
      element: <Share />,
      children: [
        {
          index: true,
          element: <ShareIndex />,
        },
      ],
    },
    // 邀请码活动 已废弃，请维护 v2 版邀请码
    {
      path: "yqm",
      element: <YQM />,
      children: [
        { index: true, element: <YQMIndex /> },
        { path: "ios_test", element: <YQMIndex /> },
        // 领取 vip 页面
        { path: "end", element: <YQMEnd /> },
        // 领取 vip 页面
        { path: "get", element: <YQMGet /> },
        // 领取成功 页面
        { path: "success", element: <YQMSuccess /> },
        // 展示会员用户拥有的邀请码列表
        {
          path: "list",
          element: <YQMList />,
          // 该页面已废弃，入口替换到新版本 !!!
          loader: () => redirect("/h5/yqmv2/list", 302),
        },
        // ios 领取活动首页
        { path: "iosLogin", element: <YQMIOSLogin /> },
        // ios 领取页面
        { path: "getIos", element: <YQMGetIOS /> },
        // ios 成功领取页面
        { path: "successIos", element: <YQMSuccessIOS /> },
      ],
    },

    // 邀请码活动 version 2
    {
      path: "yqmv2",
      element: <Outlet />,
      children: [
        // 邀请码首页
        { index: true, element: <YQMV2Index /> },
        // 领取 vip
        { path: "get", element: <YQMV2Get /> },
        // 领取 vip 结束
        { path: "success", element: <YQMV2Success /> },
        // 邀请码列表
        { path: "list", element: <YQMV2List /> },
        // 邀请码测试
        { path: "indexTest", element: <YQMV2IndexTest /> },
      ],
    },
 // 邀请码活动 version 3
    {
      path: "yqmv3",
      element: <Outlet />,
      children: [
        // 邀请码首页
        { index: true, element: <YQMV3Index /> },
        // 领取 vip
        { path: "get", element: <YQMV3Get /> },
        // 领取 vip 结束
        { path: "success", element: <YQMV3Success /> },
        // 邀请码列表
        { path: "list", element: <YQMV3List /> },
        // 邀请码测试
        { path: "indexTest", element: <YQMV3IndexTest /> },
      ],
    },
     // 邀请码活动 version 3
     {
      path: "yqmv4",
      element: <Outlet />,
      children: [
        // 邀请码首页
        { index: true, element: <YQMV4Index /> },
        // 领取 vip
        { path: "inlet", element: <YQMV4Inlet /> },
        // 邀请码列表
        { path: "list", element: <YQMV4List /> },
        { path: "get", element: <YQMV4Get /> },
      ],
    },
    //邀请码活动version5
    {
      path: "yqmv5",
      element: <Outlet />,
      children: [
        // 邀请码首页
        { index: true, element: <YQMV5Index /> },
        { path: "inlet", element: <YQMV5Inlet /> },
        { path:"code", element: <YQMV5Code /> },
        { path: "get", element: <YQMV5Get /> },
        // 领取 vip 结束
        { path: "success", element: <YQMV5Success /> },
        // 邀请码列表
        { path: "list", element: <YQMV5List /> },
        // 领取 vip
      ],
    },
    // 活动 领取 vip
    {
      path: "ac-get-vip",
      element: <Outlet />,
      children: [
        { index: true, element: <AcGetVip /> },
        // 推广员获取二维码界面
        { path: "extension", element: <AcGetVipExtension /> },
      ],
    },

    // 购买 vip
    {
      path: "buy-vip",
      element: <Outlet />,
      children: [
        { index: true, element: <BuyVip /> },
        { path: "end", element: <BuyVipEnd /> },
        // 关注公众号，产品不让改链接，只能沿用 sell
        { path: "sell", element: <BuyVipSell /> },
        // 卖 vip
        { path: "sell2", element: <BuyVipSell2 /> },
      ],
    },

    // 音乐周报
    {
      path: "music-weekly",
      element: <Outlet />,
      children: [
        { path: "mini", element: <MusicWeeklyMini /> }, // 小程序内部页面
        { path: "get", element: <MusicWeeklyGet /> }, // 公众号页面
      ],
    },

    // 橄榄树音乐
    {
      path: "ac-olive-bookmark",
      element: <Outlet />,
      children: [
        { index: true, element: <AcOliveBookmarkIndex type="olive_classical_musis_bookmark" /> }, // 橄榄书签
        { path: "class", element: <AcOliveBookmarkIndex type="olive_classical_musis_class" /> }, // 橄榄课堂
      ],
    },

    // 新 LCME 推广分销
    {
      path: "extension",
      element: <Extension />,
    },

    // 蓝牙翻页踏板
    {
      path: "pedal",
      element: <Outlet />,
      children: [
        { index: true, element: <Pedal /> },
        { path: "pedal2", element: <Pedal2 /> },
      ],
    },
  ],
};

export default H5Routes;
