import { t } from './';
export default {
    "登录失效，请重新登录。": 1,
    "自动匹配经典录音版本一键听，": 2,
    "边听大师演绎边读谱，细节处找差距": 3,
    "曲集-乐谱-声部-乐章层级清晰": 4,
    "预置六大专业分类标签，快速精准筛选查找": 5,
    "根据排练和演出需求，随心创建、编辑和分享谱单": 6,
    "功能强大的标注、录音、节拍器等辅助工具，还支持将录制": 7,
    "的演奏音频和乐谱注释文件分享给其他乐手或师生": 8,
    "首页": 9,
    "产品介绍": 10,
    "关于我们": 11,
    "用户": 12,
    "退出": 13,
    "登录": 14,
    "导入乐谱": 15,
    "纸谱一键轻松拍（自动框选、自动正反、变形校正）": 16,
    "专治乐谱画质疑难杂症，一键去噪、去阴影、自动亮度白平衡": 17,
    "全终端导入（手机相册、手机文件、平板文件、电脑文件……）": 18,
    "全格式支持（jpg、png、pdf、xml、midi、……）": 19,
    "解析封面": 20,
    "上传封面": 21,
    "生成曲谱": 22,
    "上传 pdf": 23,
    "上传失败": 24,
    "仅支持上传 PDF 文件": 25,
    "导入": 26,
    "已导入": 27,
    "完成": 28,
    "取消": 29,
    "乐谱导入成功": 30,
    "请在APP「导入」-": 31,
    "「文件」中浏览": 32,
    "上传中": 33,
    "正在上传": 34,
    "服务器开小差了": 35
}